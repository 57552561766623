<template>
  <div class="about-us-container col-center">
    <img src="../assets/icon/aboutUs/erCord.png" />
    <p class="about-us-title">关注公众号了解更多与反馈</p>
    <p class="about-us-tip">1.微信识别二维码或搜索“Meta彼岸”公众号</p>
    <p class="about-us-tip">2.公众号底部菜单--问题反馈</p>
    <p class="about-us-bottom">区块链技术由智链2.0提供支持</p>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.$store.commit('HIDE_APPLOADING')
  },
}
</script>
<style lang="less" scoped>
.about-us-container {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/icon/aboutUs/bg.png');
  background-repeat: repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: 100% auto;
  background-color: transparent;
  img {
    width: 10.4375rem;

    height: auto;
    margin-bottom: 1.875rem;
  }
  .about-us-title {
    font-size: 1.125rem;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.5625rem;
    letter-spacing: 0.125rem;
    margin-bottom: 0.375rem;
  }
  .about-us-tip {
    width: 15rem;
    text-align: left;
    font-size: 0.75rem;
    font-family: lantingheiweight;
    font-weight: 600;
    color: #cccccc;
    line-height: 1.625rem;
    word-break: break-all;
  }
  .about-us-bottom {
    position: absolute;
    bottom: 1.9375rem;
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #999999;
    line-height: 1.1875rem;
  }
}
</style>